<template>
  <div class="overlay" v-if="visible">
    <div class="popup">
      <button class="close-btn" @click="visible = false">×</button>
      <div class="header">
        <div class="logo">
          <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path d="M 20 20 L 80 20 L 50 50 Z" fill="#0b3d52"/>
            <path d="M 20 80 L 80 80 L 50 50 Z" fill="#5ebcb8"/>
          </svg>
        </div>
        <h2 class="title">YB</h2>
        <div class="subtitle">Downloading report</div>
      </div>
      <div class="progress-container">
        <div class="progress-text">{{ Math.round(progress) }}%</div>
        <div class="progress-bar">
          <div class="progress-fill" :style="{ width: progress + '%' }"></div>
        </div>
      </div>
      <div class="fact-container">
        <div class="fact-title">Did you know?</div>
        <div class="fact-text" :class="{ 'fade': factFading }">{{ currentFact }}</div>
      </div>
      <div class="countdown">
        <span>{{ timeRemainingText }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
  initialVisible: {
    type: Boolean,
    default: true
  },
  totalTimeMs: {
    type: Number,
    default: 20000 // 20 seconds
  }
});

const emit = defineEmits(['complete']);

const visible = ref(props.initialVisible);
const progress = ref(0);
const currentTargetProgress = ref(0);
const startTime = ref<number | null>(null);
const currentFactIndex = ref(0);
const factFading = ref(false);
const lastFactUpdateProgress = ref(0);
const animationFrameId = ref<number | null>(null);

const facts = [
  "Generating AI report...",
  "First time generation takes approximately 20 seconds. Our engines are fully analyzing.",
  "Agility is not just speed, but the ability to make better decisions under uncertainty.",
  "Companies with high agility scores grow up to 25% faster in dynamic markets.",
  "IT agility plays a key role in how fast organizations can seize new opportunities.",
  "A mismatch between agility and market dynamics makes companies more vulnerable.",
  "Sensing, responding, and learning together define how well a company adapts.",
  "Your brain creates time illusions - that's why waiting feels longer than it actually is.",
  "Focused attention makes time seem to pass more quickly.",
  "AI models optimize thousands of variables simultaneously during report generation."
];

const currentFact = computed(() => facts[currentFactIndex.value]);

const timeRemainingText = computed(() => {
  if (!startTime.value) return "Estimated time: 20 seconds";
  
  const elapsed = Date.now() - startTime.value;
  const remainingTime = Math.max(0, Math.ceil((props.totalTimeMs - elapsed) / 1000));
  
  if (progress.value >= 100) {
    return "Loading complete";
  } else if (progress.value > 80) {
    return `Almost there: ${remainingTime} seconds remaining`;
  } else if (progress.value > 50) {
    return `Processing: ${remainingTime} seconds remaining`;
  } else {
    return `Estimated time: ${remainingTime} seconds`;
  }
});

const updateProgress = () => {
  if (!startTime.value) return;
  
  const elapsed = Date.now() - startTime.value;
  const elapsedPercent = Math.min(100, (elapsed / props.totalTimeMs) * 100);
  
  // Calculate target progress with natural variation
  if (elapsedPercent < 30) {
    currentTargetProgress.value = elapsedPercent * 1.3;
  } else if (elapsedPercent < 70) {
    currentTargetProgress.value = 39 + (elapsedPercent - 30) * 0.7;
  } else {
    currentTargetProgress.value = 67 + (elapsedPercent - 70) * 1.1;
  }
  
  // Cap at 99% until complete
  currentTargetProgress.value = Math.min(elapsed >= props.totalTimeMs ? 100 : 99, currentTargetProgress.value);
  
  // Smooth progress updates
  progress.value = progress.value + (currentTargetProgress.value - progress.value) * 0.1;
  
  // Update fact less frequently (every ~20% progress)
  if (progress.value - lastFactUpdateProgress.value > 20) {
    updateFact();
    lastFactUpdateProgress.value = progress.value;
  }
  
  // Complete or continue
  if (progress.value < 100) {
    animationFrameId.value = requestAnimationFrame(updateProgress);
  } else {
    currentFactIndex.value = 0;
    facts[0] = "Report generated successfully!";
    
    setTimeout(() => {
      emit('complete');
    }, 1500);
  }
};

const updateFact = () => {
  factFading.value = true;
  
  setTimeout(() => {
    // Pick a new fact (avoid repeating)
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * facts.length);
    } while (newIndex === currentFactIndex.value && facts.length > 1);
    
    currentFactIndex.value = newIndex;
    factFading.value = false;
  }, 1500); // Match the CSS transition time
};

onMounted(() => {
  startTime.value = Date.now();
  updateProgress();
});

onBeforeUnmount(() => {
  if (animationFrameId.value) {
    cancelAnimationFrame(animationFrameId.value);
  }
});
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background-color: #fff;
  max-width: 400px;
  width: 90%;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  padding: 24px;
  position: relative;
  animation: popIn 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

@keyframes popIn {
  0% { transform: scale(0.9); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  margin: 0 auto 5px;
  width: 60px;
  height: 60px;
  position: relative;
}

.logo svg {
  width: 100%;
  height: 100%;
}

.title {
  margin: 0;
  color: #0b3d52;
}

.subtitle {
  font-size: 0.9rem;
  color: #0b3d52;
  margin-top: 5px;
}

.progress-container {
  margin: 1.5rem 0;
  position: relative;
}

.progress-bar {
  height: 5px;
  background-color: #f5f5f5;
  border-radius: 20px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #5ebcb8, #0b3d52);
  width: 0%;
  border-radius: 20px;
  transition: width 0.6s ease;
  position: relative;
}

.progress-fill::after {
  content: '';
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background: linear-gradient(to right, transparent, rgba(255,255,255,0.5), transparent);
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

.progress-text {
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 0.85rem;
  font-weight: 600;
  color: #0b3d52;
}

.fact-container {
  min-height: 90px;
  margin: 0 auto;
  text-align: left;
  padding: 1rem;
  border-radius: 8px;
  background-color: #f5f5f5;
  border-left: 4px solid #5ebcb8;
}

.fact-title {
  font-weight: 600;
  margin-bottom: 0.8rem;
  color: #0b3d52;
  display: flex;
  align-items: center;
}

.fact-title::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #5ebcb8;
  margin-right: 8px;
  animation: pulse 2s infinite;
}

.fact-text {
  line-height: 1.6;
  opacity: 1;
  transition: opacity 1.5s ease;
  color: #333333;
  font-size: 0.95rem;
}

.fact-text.fade {
  opacity: 0;
}

.countdown {
  margin-top: 1.2rem;
  font-size: 0.85rem;
  color: #0b3d52;
  text-align: center;
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #999;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s;
}

.close-btn:hover {
  color: #0b3d52;
}
</style> 