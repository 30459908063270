<template>
	<div>
		<!-- Download Button -->
		<Button @click="handleClick" :disabled="disabled || isLoading" class="button-default header-button ici_button"
			:class="[!isHovered ? (isDark ? 'button-normal-dark' : 'button-normal') : 'button-over']" :style="btnStyle"
			@mouseenter="handleHover(true)" @mouseleave="handleHover(false)">
			<div class="mx-auto"
				style="text-align: center; display: flex; align-items: center; gap: 8px; justify-content: center;">
				<Loader2Icon v-if="isLoading" class="animate-spin" />
				<DownloadIcon v-else />
				<label>{{ isLoading ? 'Preparing Download...' : 'Download Report' }}</label>
			</div>
		</Button>

		<!-- Download Modal -->
		<Dialog v-model:visible="showModal" modal header="Download Reports" :style="{ width: '50vw' }"
			class="download-modal">
			<!-- Format Selection -->
			<div class="mb-4">
				<h3 class="text-lg font-medium mb-2">Select Format</h3>
				<div class="flex gap-3">
					<div v-for="format in availableFormats" :key="format.value" @click="selectedFormat = format.value"
						:class="[
							'cursor-pointer p-3 rounded-lg border transition-colors',
							selectedFormat === format.value ? 'border-blue-500 bg-blue-50' : 'border-gray-200 hover:bg-gray-50'
						]">
						<div class="flex items-center gap-2">
							<component :is="format.icon" class="w-5 h-5" />
							<span>{{ format.label }}</span>
						</div>
					</div>
				</div>
			</div>

			<!-- Company Overview Section -->
			<div class="mb-4">
				<h3 class="text-xl font-semibold mb-2">Company Overview</h3>
				<div class="p-3 border rounded-lg transition-colors relative hover:bg-gray-50 cursor-pointer"
					:class="[loadingStates.overview ? 'bg-gray-100' : '']" @click="downloadCompanyOverview">
					<div class="flex items-center justify-between">
						<div>
							<h4 class="font-medium">Complete Overview</h4>
							<p class="text-sm text-gray-600">All enablers and criteria</p>
						</div>
						<Loader2Icon v-if="loadingStates.overview" class="animate-spin text-gray-500" />
						<DownloadIcon v-else class="text-gray-500" />
					</div>
				</div>
			</div>

			<!-- Enablers Section -->
			<div>
				<h3 class="text-xl font-semibold mb-2">Individual Enabler Reports</h3>
				<div class="grid grid-cols-2 gap-4">
					<div v-for="section in enablerSections" :key="section.code"
						class="p-3 border rounded-lg transition-colors relative"
						:class="[loadingStates[section.code] ? 'bg-gray-100' : 'hover:bg-gray-50 cursor-pointer']"
						@click="downloadEnablerReport(section.code)">
						<div class="flex items-center justify-between">
							<div>
								<h4 class="font-medium">{{ section.name }}</h4>
								<p class="text-sm text-gray-600">{{ section.criteria?.length || 0 }} criteria</p>
							</div>
							<Loader2Icon v-if="loadingStates[section.code]" class="animate-spin text-gray-500" />
							<DownloadIcon v-else class="text-gray-500" />
						</div>
					</div>
				</div>
			</div>

			<YBLoadingPopup
				v-if="showLoadingPopup"
				:initialVisible="true"
				:totalTimeMs="estimatedTimeMs"
				@complete="handleDownloadComplete"
			/>

			<!-- Toast for notifications -->
			<Toast position="bottom-right" />
		</Dialog>
	</div>
</template>

<script setup lang="ts">
import { ref, computed, reactive } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { Application } from '@/data/Application';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import {
	DownloadIcon,
	FileTextIcon,
	FileIcon,
	FileCodeIcon,
	Loader2Icon
} from 'lucide-vue-next';
import YBLoadingPopup from '../common/YBLoadingPopup.vue';

const route = useRoute();
const toast = useToast();
const showModal = ref(false);
const isLoading = ref(false);
const selectedFormat = ref('pdf');
const showLoadingPopup = ref(false);
const estimatedTimeMs = ref(20000); // Default 20 seconds

type LoadingStates = {
	overview: boolean;
	[key: string]: boolean; // This allows any string key
};

const loadingStates = reactive<LoadingStates>({
	overview: false,
	NC: false,
	OG: false,
	OA: false,
	TII: false,
	HII: false,
	OC: false,
	HKS: false,
	SC: false
});

// Props with TypeScript interface
interface Props {
	dark?: boolean;
	hover?: boolean;
	disabled?: boolean;
	btnStyle?: string;
	modelValue?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	dark: false,
	hover: false,
	disabled: false,
	btnStyle: '',
	modelValue: false
});

// Emits
const emit = defineEmits<{
	(e: 'update:modelValue', value: boolean): void;
	(e: 'buttonClick'): void;
	(e: 'hover', value: boolean): void;
}>();

// Computed properties for reactive state
const isDark = computed(() => props.dark);
const isHovered = computed(() => props.hover);

// Event handlers
const handleClick = () => {
	emit('buttonClick');
	openModal();
};

const handleHover = (value: boolean) => {
	emit('hover', value);
};

const openModal = () => {
	showModal.value = true;
	emit('update:modelValue', true);
};

// Structure data
const enablerSections = [
	{
		name: "Network Characteristics",
		code: "NETWORK_CHARACTERISTICS",
		criteria: [
			{ name: "Relationships based on trust", code: "C1" },
			{ name: "Performance metrics, measurement and benchmarking", code: "C2" },
			{ name: "Process integration and collaborative work", code: "C3" },
			{ name: "Knowledge sharing", code: "C4" },
			{ name: "Quick connect and disconnect", code: "C5" }
		]
	},
	{
		name: "Organisational Governance",
		code: "ORGANISATIONAL_GOVERNANCE",
		criteria: [
			{ name: "Empowerment & rewards", code: "C6" },
			{ name: "Performance measures", code: "C7" },
			{ name: "Flexible Budgeting", code: "C8" }
		]
	},
	{
		name: "Organisational Architecture",
		code: "ORGANISATIONAL_ARCHITECTURE",
		criteria: [
			{ name: "Standardisation & simplification", code: "C9" },
			{ name: "Modularity", code: "C10" },
			{ name: "Integration", code: "C11" }
		]
	},
	{
		name: "Technical IT Infrastructure",
		code: "TECHNICAL_IT_INFRASTRUCTURE",
		criteria: [
			{ name: "IT connectivity", code: "C12" },
			{ name: "IT compatibility", code: "C13" },
			{ name: "Application functionality", code: "C14" }
		]
	},
	{
		name: "Human IT Infrastructure",
		code: "HUMAN_IT_INFRASTRUCTURE",
		criteria: [
			{ name: "Business understanding", code: "C15" },
			{ name: "Technical knowledge", code: "C16" },
			{ name: "Management skills", code: "C17" },
			{ name: "Technical skills", code: "C18" }
		]
	},
	{
		name: "Organisational Culture",
		code: "ORGANISATIONAL_CULTURE",
		criteria: [
			{ name: "Leadership by coaching", code: "C19" },
			{ name: "Fostering a culture of knowledge sharing & learning", code: "C20" },
			{ name: "Interactions among employees", code: "C21" }
		]
	},
	{
		name: "Human Knowledge & Skills",
		code: "HUMAN_KNOWLEDGE_&_SKILLS",
		criteria: [
			{ name: "Knowledge (tacit), skills variety and redeployability", code: "C22" },
			{ name: "Speed of acquiring and developing new skills", code: "C23" }
		]
	},
	{
		name: "SRL Capabilities",
		code: "SRL_CAPABILITIES",
		criteria: []
	}
];

// Available formats with Lucide icons
const availableFormats = [
	{ label: 'PDF', ext: '.pdf', value: 'pdf', icon: FileTextIcon },
	{ label: 'Markdown', ext: '.md', value: 'markdown', icon: FileCodeIcon },
	{ label: 'Word', ext: '.docx', value: 'word', icon: FileIcon }
];

// Modified download functions
const downloadCompanyOverview = async () => {
	if (loadingStates.overview) return;

	try {
		loadingStates.overview = true;
		showLoadingPopup.value = true;

		const response = await axios.get(
			`${Application.instance.apiEndPoint}/reports/company/${route.params.id}`,
			{
				params: { 
					format: selectedFormat.value,
					refresh: true // Always get fresh report when showing loading
				},
				responseType: selectedFormat.value === 'markdown' ? 'text' : 'blob'
			}
		);

		await downloadFile(response.data, `company-overview${availableFormats.find(x=>x.value === selectedFormat.value).ext ?? '.txt'}`);

		toast.add({
			severity: 'success',
			summary: 'Success',
			detail: 'Report downloaded successfully',
			life: 3000
		});
	} catch (error: any) {
		console.error('Error downloading company overview:', error);
		showLoadingPopup.value = false;
		toast.add({
			severity: 'error',
			summary: 'Download Failed',
			detail: error.response?.data || 'Failed to download report',
			life: 5000
		});
	} finally {
		loadingStates.overview = false;
	}
};

const downloadEnablerReport = async (enablerCode: keyof LoadingStates) => {
	if (loadingStates[enablerCode]) return;

	try {
		loadingStates[enablerCode] = true;
		showLoadingPopup.value = true;

		const response = await axios.get(
			`${Application.instance.apiEndPoint}/reports/enabler/${route.params.id}/${enablerCode}`,
			{
				params: { 
					format: selectedFormat.value,
					refresh: true // Always get fresh report when showing loading
				},
				responseType: selectedFormat.value === 'markdown' ? 'text' : 'blob'
			}
		);

		await downloadFile(response.data, `${enablerCode}-report${availableFormats.find(x=>x.value === selectedFormat.value)?.ext ?? '.txt'}`);

		toast.add({
			severity: 'success',
			summary: 'Success',
			detail: 'Report downloaded successfully',
			life: 3000
		});
	} catch (error: any) {
		console.error('Error downloading enabler report:', error);
		showLoadingPopup.value = false;
		toast.add({
			severity: 'error',
			summary: 'Download Failed',
			detail: error.response?.data || 'Failed to download report',
			life: 5000
		});
	} finally {
		loadingStates[enablerCode] = false;
	}
};

const downloadFile = (data: Blob, filename: string) => {
	const url = window.URL.createObjectURL(new Blob([data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

const handleDownloadComplete = () => {
	showLoadingPopup.value = false;
};
</script>

<style scoped>
.download-modal {
	@apply font-sans;
}

/* .download-btn {
	@apply bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors;
} */

/* Inherit existing button styles */
.ici_button {
	font-family: "Public Sans";
	font-size: 16px;
	border-radius: 10px !important;
	border-style: solid !important;
	border-width: 1px !important;
	font-weight: 700;
}

.button-normal {
	color: #0d3d52 !important;
	border-color: #0d3d52 !important;
}

.button-normal-dark {
	color: #ffffff !important;
	background-color: #0d3d52 !important;
}

.button-over {
	background-color: #5ebcb8 !important;
	border-color: #5ebcb8 !important;
	color: #1e1e1e !important;
}

/* Add this for smoother spin animation */
.animate-spin {
	animation: spin 1s linear infinite;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
</style>
